// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-faqs-js": () => import("./../../../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-routine-shared-js": () => import("./../../../src/pages/routine/shared.js" /* webpackChunkName: "component---src-pages-routine-shared-js" */),
  "component---src-pages-shareapp-js": () => import("./../../../src/pages/shareapp.js" /* webpackChunkName: "component---src-pages-shareapp-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-workout-shared-js": () => import("./../../../src/pages/workout/shared.js" /* webpackChunkName: "component---src-pages-workout-shared-js" */),
  "component---src-templates-workout-js": () => import("./../../../src/templates/workout.js" /* webpackChunkName: "component---src-templates-workout-js" */),
  "component---src-templates-workouts-js": () => import("./../../../src/templates/workouts.js" /* webpackChunkName: "component---src-templates-workouts-js" */)
}

